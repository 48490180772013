interface PscSEOProps {
  loaderData: {
    pscCode: any;
    pscInfo: any;
  };
}

export default function PscSEO({ loaderData: { pscCode, pscInfo } }: PscSEOProps) {
  const isBrowser = typeof window !== "undefined";
  const isLocal = isBrowser && window.location.hostname.includes("localhost");
  const additionalType = "https://www.acquisition.gov/psc";
  const sameAs = [
    "https://www.fpds.gov/fpdsng_cms/index.php/en/worksite/products_and_services_codes",
    "https://sam.gov/content/product-service-code-manual",
  ];

  // Generate the URL dynamically based on the PSC code
  // The first character of the PSC code typically represents the category
  const pscCategory = pscCode?.charAt(0)?.toLowerCase() || "";
  const categoryMapping: Record<string, string> = {
    r: "r-professional-administrative-and-management-support-services",
    a: "a-research-and-development",
    b: "b-special-studies-and-analyses-not-r-d",
    c: "c-architect-and-engineering-services",
    d: "d-automatic-data-processing-and-telecommunication-services",
    f: "f-natural-resources-and-conservation-services",
    g: "g-social-services",
    h: "h-quality-control-testing-and-inspection-services",
    j: "j-maintenance-repair-and-rebuilding-of-equipment",
    k: "k-modification-maintenance-and-repair-of-miscellaneous-supplies-and-equipment",
    l: "l-technical-representative-services",
    m: "m-operation-of-government-owned-facilities",
    n: "n-installation-of-equipment",
    p: "p-salvage-services",
    q: "q-medical-services",
    s: "s-utilities-and-housekeeping-services",
    t: "t-photographic-mapping-printing-and-publications-services",
    u: "u-education-and-training-services",
    v: "v-transportation-travel-and-relocation-services",
    w: "w-lease-or-rental-of-equipment",
    x: "x-lease-or-rental-of-facilities",
    y: "y-construction-of-structures-and-facilities",
    z: "z-maintenance-repair-or-alteration-of-real-property",
    "1": "1-subsistence",
    "2": "2-clothing-individual-equipment-and-insignia",
    "3": "3-subsistence-non-food-box-lunches-etc",
    "4": "4-special-clothing",
    "5": "5-fuels-lubricants-oils-and-waxes",
    "6": "6-ammunition-and-explosives",
    "7": "7-furniture",
    "8": "8-materials-handling-equipment",
    "9": "9-weapons",
  };

  const categoryPath = categoryMapping[pscCategory] || "";
  const url = `https://www.acquisition.gov/psc-manual/${categoryPath}`;

  // additional properties
  const subjectOf = [
    {
      "@type": "GovernmentService",
      name: "Federal Procurement Reporting",
      provider: {
        "@type": "GovernmentOrganization",
        name: "General Services Administration",
      },
      serviceOutput: {
        "@type": "Thing",
        name: "Federal Procurement Data System (FPDS) Reports",
      },
    },
  ];
  const inDefinedTermSet = {
    "@type": "DefinedTermSet",
    name: "Product and Service Codes (PSC) Manual",
    url: "https://www.acquisition.gov/psc-manual",
  };

  const identifier = {
    "@type": "PropertyValue",
    propertyID: "PSC",
    value: pscCode,
  };

  const mainEntityOfPage = {
    "@type": "WebPage",
    url: url,
  };

  const isPartOf = {
    "@type": "DefinedTermSet",
    name: "Federal Procurement Data System Product and Service Codes",
    url: "https://www.acquisition.gov/psc",
  };

  const potentialAction = {
    "@type": "FindAction",
    name: "Find Contract Opportunities",
    target: {
      "@type": "EntryPoint",
      urlTemplate: `https://sam.gov/search/?index=opp&page=1&pscs=${pscCode}`,
      actionPlatform: ["http://schema.org/DesktopWebPlatform", "http://schema.org/MobileWebPlatform"],
    },
  };
  const associatedMedia = {
    "@type": "DataDownload",
    name: "PSC Manual PDF",
    description: "Statistical data about the Product and Service Codes (PSC) used in federal procurement",
    encodingFormat: "application/pdf",
    url: "https://www.acquisition.gov/sites/default/files/page_file_uploads/PSC%20Manual%20-%20Final%20-%209%20August%202015.pdf",
  };

  const category = {
    "@type": "DefinedTerm",
    termCode: pscInfo?.object_class_code,
    name: pscInfo?.object_class_code_description,
  };

  // Now building JSON-LD object
  const scriptData = {
    "@context": "https://schema.org",
    "@type": "DefinedTerm",
    termCode: pscCode,
    name: pscInfo?.psc_code_long_name,
    description: pscInfo?.psc_code_notes,
    inDefinedTermSet,
    identifier,
    additionalType,
    url,
    sameAs,
    mainEntityOfPage,
    isPartOf,
    category,
    subjectOf,
    keywords: pscInfo?.psc_code_includes,
    potentialAction,
    associatedMedia,
    usageInfo:
      "This PSC code is used in federal procurement to categorize and report on specific types of products, services, or R&D purchased by the government.",
  };

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(scriptData) }} />;
}
